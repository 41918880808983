import { Noise, Pattern } from "./pattern";

export const Backdrop = ({ type = "yellow_w_gray" }) => {
    return (
        <div className="absolute top-0 flex h-[100vh] w-[100vw] bg-[hsl(48,28%,95%)]">
            <Noise className="absolute inset-0 h-full w-full opacity-75" />
            <Pattern className="absolute inset-0 top-[-20px] h-[calc(100%+20px)] w-full" />
        </div>
    );
};
